@import './variables.scss';

@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Prompt:wght@300;400;500;600;700&display=swap');
// font-family: 'Montserrat', sans-serif;
// font-family: 'Prompt', sans-serif;



html {
    font-size: 24px;

    @media (max-width: 1260px) {
        font-size: 22px;
    }

    @media (max-width: 1160px) {
        font-size: 20px;
    }

    @media (max-width: 1060px) {
        font-size: 18px;
    }

    @media (max-width: 980px) {
        font-size: 16px;
    }
}

body,
html {
    min-height: 100vh;
    font-family: 'Montserrat', sans-serif;
}

#root {}

html, body, p, div, ol, ul, li,
h1, h2, h3, h4, h5, figure {
    margin: 0;
    padding: 0;
}

button {
    font-size: 1rem;
}

.p-like,
p, li {
    line-height: 1.15em;
    letter-spacing: .13em;
}
